<template>
  <svg
    class="w-full aspect-square"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="true"
  >
    <circle
      cx="50"
      cy="50"
      r="45"
      class="fill-gray-200"
    />
    <text
      x="50"
      y="50"
      class="fill-gray-950"
      font-size="34"
      text-anchor="middle"
      alignment-baseline="central"
    >
      {{ initials }}
    </text>
  </svg>
</template>

<script setup lang="ts">

// Props definition with types
const props = defineProps<{
  firstName: string | undefined | null;
  lastName: string | undefined | null;
}>();


// Computed property for initials
const initials = computed(() => {
  const firstInitial = props.firstName ? props.firstName.charAt(0) : '';
  const lastInitial = props.lastName ? props.lastName.charAt(0) : '';
  return `${firstInitial}${lastInitial}`;
});

</script>
